import React from 'react';
import _ from 'lodash';
import Popover from 'components/Popover';
import Button from 'components/Button';
import Sort from './sort';
import styles from './filters.module.scss';

const Filters = ({
  sort,
  sortPopoverOpen,
  sortToggle,
  setSort,
  vehicleCardType,
  setVehicleCardType,
}) => {
  return (
    <div className={styles.pricesRealizedFilterContainer}>
      <Popover
        showCaretDown={true}
        active={!!sort}
        label="Sort"
        id="sort"
        popoverOpen={sortPopoverOpen}
        toggle={sortToggle}
      >
        <Sort
          selected={sort}
          update={value => {
            _.isFunction(setSort) && setSort(value);
            _.isFunction(sortToggle) && sortToggle();
          }}
        />
      </Popover>
      <Button
        type={
          vehicleCardType === 'grid'
            ? 'app-primary-button'
            : 'app-default-button'
        }
        handleClick={() =>
          _.isFunction(setVehicleCardType) && setVehicleCardType('grid')
        }
      >
        Grid
      </Button>
      <Button
        type={
          vehicleCardType === 'list'
            ? 'app-primary-button'
            : 'app-default-button'
        }
        handleClick={() =>
          _.isFunction(setVehicleCardType) && setVehicleCardType('list')
        }
      >
        List
      </Button>
    </div>
  );
};

export default Filters;
