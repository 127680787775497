import React, { useEffect, useState } from 'react';
import { graphql, navigate } from 'gatsby';
import PriceRealized from './PriceRealized';
import {
  getAuctionEventDates,
  pastAuction,
  getEventDateAverage,
} from 'shared/helpers/auctions';
import Spinner from 'components/Spinner';
import { combineCloudinaryWithImages } from 'utils/image';
import SEO from 'components/seo';

export default ({ data }) => {
  const { contentfulWebPageAuction } = data;
  const getTotalSalePrice = auction => {
    return (
      auction.lot &&
      auction.lot.length > 0 &&
      auction.lot.map(lot => lot.salePrice).reduce((acc, val) => acc + val)
    );
  };

  useEffect(() => {
    // If auction has not passed yet, redirect to homepage
    if (
      !pastAuction(contentfulWebPageAuction.auction) ||
      !contentfulWebPageAuction.auction?.lot?.length
    ) {
      navigate('/');
    }
  }, [contentfulWebPageAuction.auction]);

  const [lots, setLots] = useState([]);
  useEffect(() => {
    const lots = contentfulWebPageAuction.auction.lot
      ?.filter(lot => lot.salePrice)
      .map(lot => {
        return {
          ...lot,
          images: combineCloudinaryWithImages(lot, 500, 3),
        };
      });
    setLots(lots);
  }, []);
  const auctionEvents = getEventDateAverage(
    getAuctionEventDates(contentfulWebPageAuction.auction)
  );
  const totalSalePrice =
    contentfulWebPageAuction.auction?.pricesRealizedOverride ??
    getTotalSalePrice(contentfulWebPageAuction.auction);

  if (
    !pastAuction(contentfulWebPageAuction.auction) ||
    !contentfulWebPageAuction.auction?.lot?.length
  ) {
    return <Spinner />;
  }
  const startSubEvent = contentfulWebPageAuction.auction?.subEvents?.find(
    subEvent => subEvent.startDate
  );
  const auctionYear = startSubEvent
    ? new Date(startSubEvent.startDate).getFullYear()
    : '';
  return (
    <>
      <SEO title={`${contentfulWebPageAuction.title} Prices Realized`} />
      <PriceRealized
        title={contentfulWebPageAuction.auction.name}
        image={contentfulWebPageAuction.featureImage}
        auctionEvents={auctionEvents}
        totalSalePrice={totalSalePrice}
        lots={lots}
        sellThroughRate={contentfulWebPageAuction.auction.sellThroughRate}
        videoPromo={contentfulWebPageAuction.auction.priceRealizedVideoPromo}
        priceRealizedFile={
          contentfulWebPageAuction.auction.priceRealizedFile?.file?.url
        }
        location={contentfulWebPageAuction.auction.location}
        auctionYear={auctionYear}
        currency={contentfulWebPageAuction.auction.currency}
      />
    </>
  );
};

export const query = graphql`
  query AuctionWebPage($id: String) {
    contentfulWebPageAuction(contentful_id: { eq: $id }) {
      title
      featureImage {
        fluid(maxWidth: 1800, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
        description
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
      }
      auction {
        name
        sellThroughRate
        currency
        priceRealizedFile {
          file {
            url
          }
        }
        pricesRealizedOverride
        priceRealizedVideoPromo
        subEvents {
          __typename
          ... on ContentfulSubEventAuction {
            startDate
            endDate
          }
        }

        lot {
          slug
          salePrice
          privateSalesPrice
          lotNumber
          onlineBiddingAvailable
          item {
            __typename
            ... on ContentfulAutomobilia {
              title
              id
              salesForceId
              cloudinaryImagesCombined {
                public_id
                height
                width
                context {
                  custom {
                    alt
                  }
                }
              }
            }

            ... on ContentfulVehicle {
              title
              modelYear
              id
              make {
                name
              }
              model
              salesForceId
              cloudinaryImagesCombined {
                public_id
                height
                width
                context {
                  custom {
                    alt
                  }
                }
              }
              cloudinaryImages1 {
                public_id
                height
                width
                context {
                  custom {
                    alt
                  }
                }
              }
              cloudinaryImages2 {
                public_id
                height
                width
                context {
                  custom {
                    alt
                  }
                }
              }
              cloudinaryImages3 {
                public_id
                height
                width
                context {
                  custom {
                    alt
                  }
                }
              }
              cloudinaryImages4 {
                public_id
                height
                width
                context {
                  custom {
                    alt
                  }
                }
              }
              cloudinaryImages5 {
                public_id
                height
                width
                context {
                  custom {
                    alt
                  }
                }
              }
              cloudinaryImages6 {
                public_id
                height
                width
                context {
                  custom {
                    alt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
