import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import LabelCard from 'components/LabelCard';
import { SORTING_OPTIONS } from '../../vehicles/data';

const Sort = ({ selected, update }) => (
  <div>
    {SORTING_OPTIONS.map((option, index) => (
      <LabelCard
        key={`${option.label}${index}`}
        selected={selected.label === option.label}
        label={option.label}
        onClick={() => _.isFunction(update) && update(option)}
      />
    ))}
  </div>
);

Sort.propTypes = {
  update: PropTypes.func,
  selected: PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.number,
  }),
};

export default Sort;
