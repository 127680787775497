import React from 'react';
import Layout from 'components/Layout';
import SEO from 'components/seo';
import Vehicles from 'features/pricesRealized/vehicles';
import YouTubePlayer from 'components/YoutubePlayer';
import HeroSection from 'components/HeroSection';
import styles from './PricesRealized.module.scss';
import { generateContentfulAssetURL } from 'utils/image';

const PricesRealized = ({
  title,
  image,
  auctionEvents,
  totalSalePrice,
  lots,
  sellThroughRate,
  videoPromo,
  priceRealizedFile,
  currency,
  auctionYear,
}) => {
  const getSellThroughRateText = () =>
    sellThroughRate ? `${sellThroughRate * 100}% Sell-Through Rate` : '';

  const currencyCode = currency || 'USD';

  const totalSalePriceFormatted = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(totalSalePrice);

  const getButtons = () => {
    return priceRealizedFile
      ? [
          {
            title: 'Download PDF',
            onClick: () => {
              const windowRef = typeof window !== `undefined` ? window : null;
              windowRef.open(priceRealizedFile);
            },
          },
        ]
      : [];
  };

  const seoImage = {
    url: generateContentfulAssetURL(image?.file?.url),
    width: image?.file?.details?.image?.width,
    height: image?.file?.details?.image?.height,
  };
  return (
    <Layout transparentHeader={true} paddingTop={false}>
      <SEO title="Prices Realized" image={seoImage} />
      <div className={styles.videoWrapper}>
        <HeroSection
          key={`pricesRealizedHeroSection`}
          description={`<p class="titleLg">${auctionEvents}</p>`}
          image={image}
          title={title}
          leftButtons={getButtons()}
          subTitles={[
            { title: 'Prices Realized' },
            { title: `Total Sold: ${totalSalePriceFormatted}`, bold: true },
            { title: getSellThroughRateText(), bold: true },
          ]}
          titleWithoutMargin={true}
        />

        <div className={styles.videoSection}>
          <YouTubePlayer url={videoPromo} />
        </div>
      </div>

      <Vehicles
        lots={lots || []}
        auctionName={title}
        auctionYear={auctionYear}
        currencyCode={currencyCode}
      />
    </Layout>
  );
};

export default PricesRealized;
