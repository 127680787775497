import React, { useState, useEffect } from 'react';
import { SORTING_OPTIONS } from './data';
import VehicleCard from 'components/VehicleCard';
import Filters from '../filters';
import styles from './vehicles.module.scss';
import {
  getContentfulItemTitle,
  getPriceFormatted,
} from 'shared/helpers/vehicles';
import InfiniteScroll from 'react-infinite-scroll-component';
import Spinner from 'components/Spinner';

const Vehicles = ({ lots = [], auctionYear, auctionName, currencyCode }) => {
  const lotsPerPage = 15;
  const [sortedLots, setSortedLots] = useState(lots);
  const [sortPopoverOpen, setSortPopoverOpen] = useState(false);
  const sortToggle = () => setSortPopoverOpen(!sortPopoverOpen);
  const [selectedSort, setSelectedSort] = useState(SORTING_OPTIONS[0]);
  const [vehicleCardType, setVehicleCardType] = useState('grid');
  const [visibleLots, setVisibleLots] = useState(
    sortedLots.slice(0, lotsPerPage)
  );

  const fetchData = () => {
    const endIndex = visibleLots.length + lotsPerPage;

    //without it infinit scroll component doesn't show the loader while fetching new lots
    setTimeout(() => {
      setVisibleLots(sortedLots.slice(0, endIndex));
    }, 100);
  };
  // Sort the lots on changing the sort option
  useEffect(() => {
    setSortedLots(sortedLots => [].concat(sortedLots).sort(selectedSort.sort));
  }, [selectedSort]);
  useEffect(() => {
    setSortedLots(lots?.sort(selectedSort.sort));
  }, [lots]);

  useEffect(() => {
    setVisibleLots(sortedLots.slice(0, lotsPerPage));
  }, [sortedLots]);
  return (
    <>
      <div className={styles.filterWrapper}>
        <Filters
          vehicleCardType={vehicleCardType}
          setVehicleCardType={type => setVehicleCardType(type)}
          sort={selectedSort}
          sortToggle={sortToggle}
          setSort={setSelectedSort}
          sortPopoverOpen={sortPopoverOpen}
        />
        <p>
          Unsold lots are not shown. Gooding &amp; Company is not responsible
          for typographical errors or omissions.
        </p>
      </div>
      <InfiniteScroll
        dataLength={visibleLots.length}
        next={fetchData}
        hasMore={visibleLots.length < sortedLots.length}
        hasChildren={visibleLots.length > 0}
        loader={<Spinner />}
      >
        <div
          className={`${styles.vehiclesContainer} ${
            vehicleCardType === 'list' ? styles.vehiclesListContainer : ''
          }`}
        >
          <div
            className={`${styles.vehiclesWrapper} ${
              vehicleCardType === 'grid'
                ? styles.gridWrapper
                : styles.listWrapper
            }`}
          >
            {visibleLots.map((lot, index) => {
              const title = getContentfulItemTitle(lot.item);
              return (
                <VehicleCard
                  key={`vehicleCard_${index}`}
                  fluidImages={lot.images?.slice(0, 3)}
                  lotNumber={lot.lotNumber}
                  price={`SOLD ${
                    lot.privateSalesPrice
                      ? ''
                      : `| ${getPriceFormatted(lot.salePrice, currencyCode)}`
                  }`}
                  slug={lot.slug}
                  makeModel={title}
                  auction={`${auctionYear} | ${auctionName}`}
                  cardType={vehicleCardType}
                  showDotsOnHover={true}
                  salesForceId={lot?.item?.salesForceId}
                  onlineBiddingAvailable={lot.onlineBiddingAvailable}
                />
              );
            })}
          </div>
        </div>
      </InfiniteScroll>
    </>
  );
};

export default Vehicles;
