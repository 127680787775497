const SORTING_OPTIONS = [
  {
    label: 'Lot Number',
    sort: (lot1, lot2) => {
      return lot1.lotNumber - lot2.lotNumber;
    },
  },
  {
    label: 'Year (Ascending)',
    sort: (lot1, lot2) => {
      return (lot1.item.modelYear || 0) - (lot2.item.modelYear || 0);
    },
  },
  {
    label: 'Year (Descending)',
    sort: (lot1, lot2) => {
      return (lot2.item.modelYear || 0) - (lot1.item.modelYear || 0);
    },
  },
  {
    label: 'Make (A to Z)',
    sort: (lot1, lot2) => {
      return (lot1.item.make?.name || '').localeCompare(
        lot2.item.make?.name || ''
      );
    },
  },
  {
    label: 'Make (Z to A)',
    sort: (lot1, lot2) => {
      return (lot2.item.make?.name || '').localeCompare(
        lot1.item.make?.name || ''
      );
    },
  },
  {
    label: 'Price (Low to High)',
    sort: (lot1, lot2) => {
      return (lot1.salePrice || '') - (lot2.salePrice || '');
    },
  },
  {
    label: 'Price (High to Low)',
    sort: (lot1, lot2) => {
      return (lot2.salePrice || '') - (lot1.salePrice || '');
    },
  },
];

export { SORTING_OPTIONS };
